import * as React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import SEO from "../components/seo";

// Patterns
import PatternFirst from "../images/pattern_01.png";

export default function Home({
  data: { page, forms, header, footer, socials, copyright, user, cookies },
}) {
  const wpContent = page.nodes[0];

  forms = forms.nodes[0]

  return (
    <Layout
      footer={footer.nodes}
      header={header.nodes}
      forms={forms}
      user={user.nodes}
      cookies={cookies.nodes}
      copyright={copyright.nodes}
      socials={socials.nodes[0].socialNetworks}
      locale={page.nodes[0].locale.locale}
      uri={page.nodes[0].uri}
    >
      <SEO title={wpContent.seo.title} description={wpContent.seo.metaDesc} />
      <header className="head">
        <nav className="breadcrumb" aria-label="Breadcrumb">
          <ol className="breadcrumb__list">
            {wpContent.seo.breadcrumbs.map((value, i) => (
              <li key={i} className="breadcrumb__item">
                <Link
                  activeClassName="breadcrumb__link__active"
                  className="breadcrumb__link"
                  to={value.url}
                  dangerouslySetInnerHTML={{
                    __html: value.text,
                  }}
                ></Link>
              </li>
            ))}
          </ol>
        </nav>
        <h1>{wpContent.pageDocuments.pageTitle}</h1>
        <div className="pattern">
          <img src={PatternFirst} alt="Pattern" />
        </div>
      </header>
      <div className="mainImage">
        <GatsbyImage
          image={
            wpContent.pageDocuments.firstScreenImg.localFile.childImageSharp
              .gatsbyImageData
          }
          alt={
            wpContent.pageDocuments.firstScreenImg.localFile.childImageSharp
              .altText || ``
          }
        />
      </div>
      <h2>{wpContent.pageDocuments.documentsBlockTitle}</h2>
      <div className="aboutIcons lined">
        {wpContent.pageDocuments.documentsList.map((value, i) => (
          <a
            key={i}
            rel="noreferrer"
            target="_blank"
            className="aboutIcons-item"
            href={value.itemFile.mediaItemUrl}
          >
            <div
              className="icon"
              dangerouslySetInnerHTML={{
                __html: wpContent.pageDocuments.documentsIcon,
              }}
            ></div>
            {value.itemName}
          </a>
        ))}
      </div>
    </Layout>
  );
}

export const query = graphql`
  {
    copyright: allWpRepeatableBlocks(
      filter: {slug: {eq: "copyrights"}}
    ) {
      nodes {
        slug
        title
        blockCopyrights {
          copyrightTextAfter
          copyrightTextBefore
          copyrightTextMiddle
          userAgreement {
            linkText
          }
          cookiesPolicy {
            linkText
          }
        }
        uri
        locale {
          locale
        }
      }
    }
    user: allWpRepeatableBlocks(filter: {slug: {eq: "ugoda-korystuvacha"}}) {
      nodes {
        slug
        title
        uri
        locale {
          locale
        }
        blockUserAgreement {
          agreementBlockId
          userAgreementText
          userAgreementButtonText
          userAgreementTitle
        }
      }
    }
    cookies: allWpRepeatableBlocks(filter: {slug: {eq: "polityka-cookies"}}) {
      nodes {
        slug
        title
        uri
        locale {
          locale
        }
        blockUserAgreement {
          agreementBlockId
          userAgreementText
          userAgreementButtonText
          userAgreementTitle
        }
      }
    }
    page: allWpPage(
      filter: { locale: { locale: { eq: "uk" } }, slug: { eq: "documents" } }
    ) {
      nodes {
        pageDocuments {
          documentsBlockTitle
          documentsIcon
          pageTitle
          documentsList {
            itemName
            itemFile {
              mediaItemUrl
            }
          }
          firstScreenImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
              }
            }
          }
        }
        uri
        title
        slug
        locale {
          locale
        }
        seo {
          title
          metaDesc
          breadcrumbs {
            text
            url
          }
        }
      }
    }
    forms: allWpRepeatableBlocks(
      filter: { locale: { locale: { eq: "uk" } }, slug: { eq: "formy" } }
    ) {
      nodes {
        slug
        title
        formSettings {
          contactFormCommentPlaceholder
          contactFormEmailPlaceholder
          contactFormSendButton
          contactFormTitle
          fieldGroupName
          modalCallbackButtonText
          modalCallbackTitle
          modalThanksButtonText
          modalThanksText
          modalThanksTitle
          modalViewingButtonText
          modalViewingFormTitle
          viewingFormDatetime
          viewingFormSendButton
          viewingFormTitle
          viewingFormUsername
        }
        locale {
          locale
        }
      }
    }
    footer: allWpRepeatableBlocks(filter: { slug: { eq: "footer" } }) {
      nodes {
        mainFooter {
          copyrightText
          footerLogo
          orderButtonIcon
          orderButtonText
          centrColumnItems {
            item
            name
          }
          leftColumnItems {
            name
            item
          }
          rightColumnItems {
            item
            name
          }
        }
        slug
        title
        locale {
          locale
        }
      }
    }
    header: allWpRepeatableBlocks(filter: { slug: { eq: "header" } }) {
      nodes {
        slug
        title
        locale {
          locale
        }
        mainHeader {
          headerLogo
          headerSubmenu {
            dropdownBtnIcon
            dropdownPages {
              pageTitle
              pageLink
            }
          }
          menuCallbackBtn {
            icon
            text
          }
          menuOrderHouseBtn {
            icon
            link
            text
          }
          headerMenuFix {
            pageLink
            pageTitle
          }
          headerMessengerMenu {
            messengerList {
              icon
              link
            }
            dropdownBtnIcon
          }
        }
      }
    }
    socials: allWpRepeatableBlocks(
      filter: {
        locale: { locale: { eq: "uk" } }
        slug: { eq: "soczialni-merezhi" }
      }
    ) {
      nodes {
        slug
        title
        locale {
          locale
        }
        socialNetworks {
          socialNetworks {
            icon
            link
            title
          }
        }
      }
    }
  }
`;
